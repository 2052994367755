
/* LG */
@media (min-width: 1200px) {

}


/* MD */
@media (min-width: 992px) and (max-width: 1199px) {

	.form-container {
		margin: 0 auto;
	}

}


/* SM */
@media (min-width: 768px) and (max-width: 991px) {
 
}


/* XS */
@media (max-width: 767px) {

	.main {
		padding : 30px 15px;
	}

	.list-prix li span.pull-right {
		float : none;
		display : block;
		margin-top : 5px;
	}

	.btn {
		display : block;
	}

	.btn-retour {
		float: left!important;
		padding-left : 0;
		font-size : 10px;
		margin-top : 10px;
		text-align : center;
		display : block;
		width: 100%;
	}
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

