
.main {
	padding : 60px 0;
}


.form-container {
	background            : #FFFFFF;
	border-radius         : 10px;
	-moz-border-radius    : 10px;
	-webkit-border-radius : 10px;
	-o-border-radius      : 10px;
	-ms-border-radius     : 10px;
	-webkit-box-shadow    : 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
	-moz-box-shadow       : 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
	box-shadow            : 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
}

#main-form {
	padding : 50px;
}