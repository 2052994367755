@import url('https://fonts.googleapis.com/css?family=Roboto+Slab:400,700');



html {
	background: url(../gfx/bg/4b.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

}

body {
	font-family: 'Roboto Slab', Arial, sans-serif;
	color:#333333;
	font-size: 14px;
	line-height: 20px;
	background-color : transparent!important;
}


hr {
	margin-top: 10px;
	margin-bottom: 20px;
	border-top: 2px solid rgba(0, 0, 0, 0.1);
}


.form-control {
	background-color : #f2f2f2;
	font-size : 14px;
}

label {
	font-weight : 700;
}



/******************************************************* TITRES */

h1 {
	padding: 0;
	margin: 0;
	font-size: 18px;
	line-height: 22px;
	font-weight : 700;
	text-transform : uppercase;
	margin-bottom : 5px;
}

h2 {
	padding: 0;
	margin: 0;
	font-size: 14px;
	line-height: 16px;
}



/******************************************************* LINK */

a {
	color: #007bff;
	text-decoration: none;
}
a:hover,
a:focus {
	color: #007bff;
	text-decoration: underline;
}
a:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}



/******************************************************* BTN */

.btn {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
	padding: 8px 15px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
	border-radius: 7px;
	-webkit-transition: all ease 0.3s;
	-moz-transition: all ease 0.3s;
	-ms-transition: all ease 0.3s;
	-o-transition: all ease 0.3s;
	transition: all ease 0.3s;

}




/******************************************************* PADDING */

.p-0	{ padding : 0; }
.p-10	{ padding : 10px; }
.p-15	{ padding : 15px; }

.p-t-0 	{ padding-top : 0px !important;  }
.p-t-5 	{ padding-top : 5px !important;  }
.p-t-10 { padding-top : 10px !important; }
.p-t-15 { padding-top : 15px !important; }
.p-t-20 { padding-top : 20px !important; }
.p-t-25 { padding-top : 25px !important; }
.p-t-30 { padding-top : 30px !important; }
.p-t-40 { padding-top : 40px !important; }

.p-b-0 	{ padding-bottom : 0px !important;  }
.p-b-5 	{ padding-bottom : 5px !important;  }
.p-b-10 { padding-bottom : 10px !important; }
.p-b-15 { padding-bottom : 15px !important; }
.p-b-20 { padding-bottom : 20px !important; }
.p-b-25 { padding-bottom : 25px !important; }
.p-b-30 { padding-bottom : 30px !important; }
.p-b-40 { padding-bottom : 40px !important; }

.p-l-0 	{ padding-left : 0px !important;  }
.p-l-5 	{ padding-left : 5px !important;  }
.p-l-10 { padding-left : 10px !important; }
.p-l-15 { padding-left : 15px !important; }
.p-l-20 { padding-left : 20px !important; }
.p-l-25 { padding-left : 25px !important; }
.p-l-30 { padding-left : 30px !important; }
.p-l-40 { padding-left : 40px !important; }

.p-r-0 	{ padding-right : 0px !important;  }
.p-r-5 	{ padding-right : 5px !important;  }
.p-r-10 { padding-right : 10px !important; }
.p-r-15 { padding-right : 15px !important; }
.p-r-20 { padding-right : 20px !important; }
.p-r-25 { padding-right : 25px !important; }
.p-r-30 { padding-right : 30px !important; }
.p-r-40 { padding-right : 40px !important; }



/******************************************************* MARGIN */

.m-0 	{ margin : 0; }

.m-t-0 	{ margin-top : 0px !important;  }
.m-t-5 	{ margin-top : 5px !important;  }
.m-t-10 { margin-top : 10px !important; }
.m-t-15 { margin-top : 15px !important; }
.m-t-20 { margin-top : 20px !important; }
.m-t-25 { margin-top : 25px !important; }
.m-t-30 { margin-top : 30px !important; }
.m-t-40 { margin-top : 40px !important; }

.m-b-0 	{ margin-bottom : 0px !important;  }
.m-b-5 	{ margin-bottom : 5px !important;  }
.m-b-10 { margin-bottom : 10px !important; }
.m-b-15 { margin-bottom : 15px !important; }
.m-b-20 { margin-bottom : 20px !important; }
.m-b-25 { margin-bottom: 25px !important;  }
.m-b-30 { margin-bottom : 30px !important; }
.m-b-40 { margin-bottom : 40px !important; }

.m-l-0	{ margin-left : 0px !important;  }
.m-l-5 	{ margin-left : 5px !important;  }
.m-l-10 { margin-left : 10px !important; }
.m-l-15 { margin-left : 15px !important; }
.m-l-20 { margin-left : 20px !important; }
.m-l-25 { margin-left : 25px !important; }
.m-l-30 { margin-left : 30px !important; }
.m-l-40 { margin-left : 40px !important; }

.m-r-0 	{ margin-right : 0px !important;  }
.m-r-5 	{ margin-right : 5px !important;  }
.m-r-10 { margin-right : 10px !important; }
.m-r-15 { margin-right : 15px !important; }
.m-r-20 { margin-right : 20px !important; }
.m-r-25 { margin-right : 25px !important; }
.m-r-30 { margin-right : 30px !important; }
.m-r-40 { margin-right : 40px !important; }
